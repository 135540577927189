import React, { useLayoutEffect, lazy, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router'


import Spinner from './pages/Spinner';
import EventCalendar from './pages/activities/event-calendar/EventCalendar';
import PageNotFound from './PageNotFound';
import PaymentTermsAndCondition from './pages/aboutus/PaymentTermsAndCondition';
import ContactForm from './pages/activities/contact/ContactForm';
// import Home from './pages/home/Home';
// import Registration from './pages/user/Registration'
// import AboutUs from './pages/aboutus/AboutUs'
// import OurServices from './pages/aboutus/OurServices'
// import PrivacyPolicy from './pages/aboutus/PrivacyPolicy'
// import Test from './Test'

const Home = lazy(() =>
  import('./pages/home/Home')
)
const AboutUs = lazy(() =>
  import('./pages/aboutus/AboutUs')
)
const OurServices = lazy(() =>
  import('./pages/aboutus/OurServices')
)
const PrivacyPolicy = lazy(() =>
  import('./pages/aboutus/PrivacyPolicy')
)

const TermsAndCondition = lazy(() =>
  import('./pages/aboutus/TermsAndCondition')
)

const ActivitySearch = lazy(() =>
  import('./pages/activities/search/ActivitySearch')
)

const ActivityResults = lazy(() => import('./pages/activities/activity-results/ActivityResults'))
const ActivityBooking = lazy(() => import('./pages/activities/activity-booking/ActivityBooking'))
const ActivityDetails = lazy(() => import('./pages/activities/activity-details/ActivityDetails'))
const ActivityList = lazy(() => import('./pages/activities/activity-results/ActivityList'))
const ActivityEnquiry = lazy(() => import('./pages/activities/activity-booking/ActivityEnquiry'))
const ActivityBooked = lazy(() => import('./pages/activities/activity-booking/ActivityBooked'))
const ActivityCountries = lazy(() => import('./pages/activities/activity-results/ActivityCountries'))
const SearchBooking = lazy(() => import('./pages/activities/manage-booking/SearchBooking'))
const Bookings = lazy(() => import('./pages/activities/manage-booking/Bookings'))
const EventCalendarBookings = lazy(() => import('./pages/activities/event-calendar/EventCalendar'))

const Faq = lazy(() => import('./pages/aboutus/Faq'))

const Main = () => {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
        <div>
            <Suspense fallback={<Spinner />}>
            <Switch>
                {/* <Route exact path="/test" component={Test}></Route> */}
                <Route exact path="/" component={ActivitySearch} />
                <Route exact path="/about-us" component={AboutUs}></Route>
                <Route exact path="/our-services" component={OurServices}></Route>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
                <Route exact path="/terms-and-condition" component={TermsAndCondition}></Route>
                <Route exact path="/payment-terms-and-condition" component={PaymentTermsAndCondition}></Route>
                <Route exact path="/activity-search-results" component={ActivityResults}/>
                <Route exact path="/activity-details" component={ActivityDetails}/>
                <Route exact path="/activity-booking" component={ActivityBooking}/>
                <Route exact path="/activity-list" component={ActivityList}/>
                <Route exact path="/activity-enquiry" component={ActivityEnquiry}/>
                <Route exact path="/activity-booked" component={ActivityBooked}/>
                <Route exact path="/activity-countries" component={ActivityCountries}/>
                <Route exact path="/search-booking" component={SearchBooking}/>
                <Route exact path="/bookings" component={Bookings}/>
                <Route exact path="/event-calendar" component={EventCalendar}/>
                <Route exact path="/contact-us" component={ContactForm}/>
                <Route exact path="/faq" component={Faq}/>
                <Route component={PageNotFound} />
            </Switch>
            </Suspense>
        </div>
  )
}

export default Main
