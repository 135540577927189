import React, { useState, useEffect, useCallback } from 'react'
import { Box, FormControlLabel, Checkbox } from '@mui/material'
import { Col, Container, Button, } from 'react-bootstrap'
import '../activity-details/selectactivity.css'
import '../activity-results/activityresult.css'
import './eventcalendar.css'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { Grid } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { getAuth } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom'

const packages = [{ "package_id": "1", "package_name": "Cricket", "color": "#C4513F" },
{ "package_id": "2", "package_name": "Golf", "color": "#4F86A4" },
{ "package_id": "3", "package_name": "Tennis", "color": "#485C2C" },
{ "package_id": "4", "package_name": "Motor Sports", "color": "#837D78" },
{ "package_id": "5", "package_name": "Football", "color": "#1F8539" },
{ "package_id": "6", "package_name": "Music", "color": " #02AFD4" }]

const EventCalendar = () => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState('');
    const [isToOpen, setIsToOpen] = useState('');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [showFilter, setShowFillter] = useState(false);
    const [upcomingEventList, setUpcomingEventList] = useState([]);
    const [filteredEventList, setFilteredEventList] = useState([]);
    const [filter, setFilter] = useState({ packages: [], package_id: [], eventDate: null })

    useEffect(() => {
        getUpcomingEventList();
    }, [])

    const getUpcomingEventList = async () => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + 'tours/upcoming_events_list')
                .then((response) => {
                    if (response.data.status) {
                        setUpcomingEventList(response.data.data?.packages);
                        setFilteredEventList(response.data.data?.packages);
                    }
                    setIsLoading(false)
                }).catch((error) => {
                    setUpcomingEventList([]);
                    setFilteredEventList([]);
                    setIsLoading(false)
                })
        } catch (error) {
            setIsLoading(false)

        }
    }

    const onPackageChange = useCallback(
        (datas, id) => {
            const flit = [...filter.packages];
            const flit_id = [...filter.package_id];
            if (flit?.includes(datas)) {
                flit.splice(flit.indexOf(datas), 1);
                flit_id.splice(flit_id.indexOf(id), 1);
            } else {
                flit.push(datas);
                flit_id.push(id);
            }
            setFilter({ ...filter, packages: flit, package_id: flit_id })
            onFilterData(flit, flit_id);
        }
    )

    const onFilterData = (flit, flit_id) => {
        let result = null
        let filteredArr = flit === null ? filter.packages : flit;
        let filteredIdArr = flit_id === null ? filter.package_id : flit_id;
        result = upcomingEventList
        if (filteredArr.length === 0) {
            result = result
        } else {
            result = result.filter((value) => {
                if (filteredIdArr.includes(value.package_type)) {
                    return value
                }
            })
        }

        if (formatDate(fromDate) === formatDate(new Date()) && formatDate(toDate) === formatDate(new Date())) {
            result = result
        } else {
            var startDate = formatDate(new Date(fromDate));
            var endDate = formatDate(new Date(toDate));

            result = result.filter(a => {
                var date = formatDate(new Date(a.event_start_date));
                return (date >= startDate && date <= endDate);
            });
        }
        // console.log("Result", result)
        setFilteredEventList(result)
    }

    const handleResetAll = () => {
        setFromDate(new Date());
        setToDate(new Date());
        setFilter({ packages: [], package_id: [], eventDate: null });
        setFilteredEventList(upcomingEventList);
    }

    function renderEventContent(eventInfo) {
        let item = JSON.parse(eventInfo.event.title);
        return (
            <>
                <Tooltip title={item.package_name} placement="top-start">
                    <span onClick={() => history.push("/activity-details?" + `${item.package_id}?package_type=${item.package_type}&package_location=${item.package_location}&country_name=${item.country_name}&package_country=${item.package_country}&package_city=${item.package_city}`)}>{item.package_name}</span>
                </Tooltip>
            </>
        )
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${mo}/${da}/${ye}`
        return newDate;
    }

    //  console.log("filter1", filter)
    //console.log("upcomingEventList", upcomingEventList.map(x=> {return ({"packageName": x.package_name, "date": x.event_start_date})}))
    return (
        <Box className='mt-3'>
            <Box className="container mb-5">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} className='filter-ec'>
                        <Box className={`filter-content-ec ${showFilter ? 'filter-content-res' : ''} mt-2`}>
                            <div class="tlt-filter-header"><div class="tltrange-filter">Filter</div>
                                <div class="tltrangereset" onClick={() => handleResetAll()} >Reset All</div></div>
                            <hr></hr>
                            <Box className="boxSizingcompt">
                                <Box className="tlttag mb-2">Select Date</Box>
                            </Box>
                            <Col xs={12} sm={12} md={12} className='px-0'>
                                <Box className="flightreportsBox">
                                    <Box className="report-tlt">From date</Box>
                                    <Box className="report-tlt-input">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                className="depositdate-picker"
                                                size="small"
                                                disableToolbar
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd-MMM-yyyy"
                                                autoOk
                                                //maxDate={new Date()}
                                                InputProps={{ readOnly: true }}
                                                placeholder="Booking From Date"
                                                value={formatDate(fromDate)}
                                                // onChange={handleBookingFromDateChange}

                                                onChange={(date) => {
                                                    // handleBookingFromDateChange(e)
                                                    setIsOpen(false);
                                                    setFromDate(formatDate(date))
                                                }}
                                                KeyboardButtonProps={{
                                                    onFocus: e => {
                                                        setIsOpen(true);
                                                    }
                                                }}
                                                PopoverProps={{
                                                    disableRestoreFocus: true,
                                                    onClose: () => {
                                                        setIsOpen(false);
                                                    }
                                                }}
                                                InputProps={{
                                                    onFocus: () => {
                                                        setIsOpen(true);
                                                    }
                                                }}
                                                open={isOpen}

                                            // KeyboardButtonProps={{
                                            //     'aria-label': 'change date',
                                            // }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xs={12} sm={12} md={12} className='px-0 mt-2'>
                                <Box className="flightreportsBox">
                                    <Box className="report-tlt">To date</Box>
                                    <Box className="report-tlt-input">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                className="depositdate-picker"
                                                size="small"
                                                disableToolbar
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="dd-MMM-yyyy"
                                                autoOk
                                                // minDate={bookingfromdatepicker}
                                                //maxDate={new Date()}
                                                InputProps={{ readOnly: true }}
                                                placeholder="Booking To Date"
                                                value={formatDate(toDate)}
                                                // onChange={handleBookingToDateChange}

                                                onChange={(date) => {
                                                    // handleBookingToDateChange(e);
                                                    setIsToOpen(false);
                                                    setToDate(formatDate(date))
                                                }}
                                                KeyboardButtonProps={{
                                                    onFocus: e => {
                                                        setIsToOpen(true);
                                                    }
                                                }}
                                                PopoverProps={{
                                                    disableRestoreFocus: true,
                                                    onClose: () => {
                                                        setIsToOpen(false);
                                                    }
                                                }}
                                                InputProps={{
                                                    onFocus: () => {
                                                        setIsToOpen(true);
                                                    }
                                                }}
                                                open={isToOpen}
                                            // KeyboardButtonProps={{
                                            //     'aria-label': 'change date',
                                            // }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                </Box>
                            </Col>

                            <Box className="package-wrapper my-3 p-0">
                                <Button
                                    className='btn-small btn-border-radius'
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => onFilterData(null, null)}
                                // disabled={isLoading}
                                >
                                    {/* {isLoading ? (<CircularProgress size={24} color="inherit" />) : "Search"} */}
                                    Search
                                </Button>
                                {/* <Button 
                            variant="contained"
                            color="secondary"
                            size="small"
                            >Reset</Button> */}
                                <Button className='btn-border btn-small ml-2' variant="contained" size="small" >Clear</Button>
                            </Box>


                            <Box className="componentboxtlt">
                                <hr></hr>
                                <Box className="boxSizingcompt">
                                    <Box className="tlttag mb-2">Experiences</Box>
                                </Box>
                                <Box className="listchecktag">
                                    <ul>
                                        {packages.map(item =>
                                            <li>
                                                <FormControlLabel
                                                    className="labelcontrolcheck mb-1"
                                                    label={item.package_name}
                                                    control={<Checkbox
                                                        name={item.package_name}
                                                        key={item.package_id}
                                                        checked={filter.packages.includes(item.package_name) ? true : false}
                                                        // checked={isDuration.includes(item) ? true : false}
                                                        onChange={() => onPackageChange(item.package_name, item.package_id)}
                                                    />}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Box className='label-detector'>
                            <ul>
                                <li className='label-cricket'><span ></span> Cricket</li>
                                <li className='label-golf'><span></span> Golf</li>
                                <li className='label-tennis'><span></span> Tennis</li>
                                <li className='label-formula'><span></span> Motor Sports</li>
                                <li className='label-football'><span></span> Football</li>
                                <li className='label-music'><span></span> Music</li>
                            </ul>
                        </Box>
                        <Button className='filter-btn-xs filter-btn' onClick={()=>setShowFillter(!showFilter)}>Filter</Button>

                        <Box>
                            <FullCalendar
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                weekends={true}
                                events={filteredEventList?.map(item => {
                                    return ({
                                        title: JSON.stringify(item),
                                        date: item.event_start_date,
                                        backgroundColor: packages.filter(x => x.package_id === item.package_type)[0]?.color,
                                        borderColor: packages.filter(x => x.package_id === item.package_type)[0]?.color
                                    })
                                })}
                                eventContent={renderEventContent}
                            //eventBackgroundColor={ite}
                            />

                        </Box>
                    </Grid>
                </Grid>

            </Box>


        </Box>


    )
}

export default EventCalendar
