import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import makeStyles from '@mui/styles/makeStyles';
import imgBg from './assets/images/no-result.png'

const PageNotFound = () => {
  return (
    <Container className='bg-white container-not-found'>
      <Row>
        <Col xs={12} sm={3}  >
          </Col>
        <Col xs={12} sm={6}  >
          <div className="no-result-box">
            <img src={imgBg} />
            <h5 className="head-2"> 404</h5>
            <h5 className="head-1"> Oops! The page you are looking for does not exist.</h5>
            <Link to="/" className="404-link filter-btn">Go back to Home</Link>

          </div>
        </Col>
        <Col xs={12} sm={3}  >
          </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;