import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import '@szhsin/react-menu/dist/index.css';
import logo from "../../assets/images/hsbc.svg";
import uxplogo from "../../assets/images/itwuxp.png";
import { Badge, Box, Button, SvgIcon } from '@mui/material';
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccountDetails } from '../../store/user/actions/userAcountActions';
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants';
import Dialog from '@mui/material/Dialog';
import Slider from "react-slick";
import img1 from '../../assets/images/sports/golf.png'
import img2 from '../../assets/images/sports/tennis.png'
import img3 from '../../assets/images/sports/f1.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './header.css'
import SignIn from './SignIn';
import SignUp from './SignUp';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory()
  const { isLoading, userDetails } = useSelector(state => state.user)
  const [open, setOpen] = useState(false);
  const [opensignmodal, setOpensignmodal] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const [openNav, setOpenNav] = React.useState(false);

  const handleClickNav = () => {
    setOpenNav((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNav(false);
  };
  const handleClickOpen = () => {
    setOpenmodal(true);
  };
  const handleClickClose = () => {
    setOpenmodal(false);
  };

  const handleSignOpen = (e) => {
    e.preventDefault()
    setOpensignmodal(true);
  };

  const handleSignClose = () => {
    setOpensignmodal(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  useEffect(() => {
    dispatch(getUserAccountDetails())
  }, [dispatch]);

  //sliderlogin 
  var lginslider = {
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  ////
  const handleBackNavigation = () => {
    if(location?.pathname === '/'){
      window.location.href = 'https://ent-lounge.bookmyshow.com';
    }
    else history.goBack();
  }

  return (
    <div className="header sticky-parent sticky-child" id="header">
      {/* {userDetails ? ( */}

      <Container className="">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="headercol ">
            {/* <a href='https://ent-lounge.bookmyshow.com' className='font-go-back'><ArrowBackIosIcon />Go Back</a> */}

            <Navbar
              collapseOnSelect
              expand="lg"
              variant="light"
              className="NavbarHeader "
            >
              <Navbar.Brand className="logoContainer">
                <div className="logo-flex">
                  <a className='font-go-back' onClick={()=> handleBackNavigation()}><ArrowBackIosIcon /></a>
                  <NavLink to="/"><img
                    src={
                      userDetails && userDetails.logo
                        ? PROFILE_IMAGE + userDetails?.logo
                        : logo
                    }
                    className="itw-logo"
                    alt="logo"
                  /></NavLink>
                  {/* <p className='font-12 mb-0'>This is a demo site.</p> */}
                </div>
              </Navbar.Brand>
              <Box>
                <NavLink to="/contact-us" className="header-icon">
                  <Tooltip title="Contact Us">
                    <IconButton className='p-1'>
                      <MailOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </NavLink>
                <NavLink to="/event-calendar" className="header-icon">
                  <Tooltip title="Event Calendar">
                    <IconButton className='p-1'>
                      <CalendarMonthIcon />
                    </IconButton>
                  </Tooltip>
                </NavLink>
              </Box>
            </Navbar>
          </Col>
        </Row>
      </Container>

      <Modal
        show={openmodal}
        onHide={handleClickClose}
        aria-labelledby="form-dialog-title"
        className="login-dialog"
      >
        <div className="dailogbox">
          <SignIn />
          <div className="slidersect">
            <div className="slideitem">
              <Slider {...lginslider}>
                <div className="imgslidebx">
                  <img src={img1} className="logoimg" alt="login-img" />
                </div>
                <div className="imgslidebx">
                  <img src={img2} className="logoimg" alt="login-img" />
                </div>
                <div className="imgslidebx">
                  <img src={img3} className="logoimg" alt="login-img" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Modal>

      {/* end login form */}

      {/* Signup popup */}
      <Modal
        show={opensignmodal}
        onHide={handleSignClose}
        aria-labelledby="form-dialog-title"
        className="dailogbox-container"
      >
        <div className="signup-modal">
          <Row>
            <Col xs={12} md={5} className="reg-left-box">
              <div className="slideitem">
                <Slider {...lginslider}>
                  <div className="imgslidebx">
                    <img src={img1} className="logoimg" alt="login-img" />
                  </div>
                  <div className="imgslidebx">
                    <img src={img2} className="logoimg" alt="login-img" />
                  </div>
                  <div className="imgslidebx">
                    <img src={img3} className="logoimg" alt="login-img" />
                  </div>
                </Slider>
              </div>
            </Col>
            <Col xs={12} md={7}>
              <SignUp />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default Header
