import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./aboutus.css"
import privacy from "../../assets/images/Termsncondition.jpg"

const PaymentTermsAndCondition = () => {
    return (
            <Container className="about-us my-3">
                <Row>
                    <Col xs={12} >
                        <h1 className='text-center my-4'>Payment Terms and Conditions</h1>
                    </Col>
                    <Col xs={12} className='pp- pp-para m-auto'>
                        <h1>1. Token Payment:</h1>
                        <p>By proceeding with a booking on our website, the User agrees to make a token payment of the following amount:
                        </p>
                        <div className='px-3'>
                            <p><b>a.</b><span> Rs. 999 for HSBC credit cardholders;</span></p>
                            <p><b>b.</b><span> Rs. 4999 for others.</span></p>
                        </div>
                        <p>This amount will be collected as a commitment towards the services requested and will be adjusted against the final payment once the services are confirmed.</p>
                    </Col>
                    {/* <Col xs={7} className='pp-leftcontainer'>
                        <Box className="privacyimgbanner"><img src={privacy} alt=""></img>
                        </Box>
                    </Col> */}
                    

                    <Col xs={12} className="mt-3 pp-para">
                    <h1>2. Service Confirmation: </h1>
                    <div className='px-3'>
                        <p>After receiving the token payment, an ITW representative will reach out to the User to discuss and understand the specific requirements for the services requested. Please note that the prices displayed on the website are indicative only and are subject to change based on the final services confirmed. The exact pricing will be communicated during this discussion.</p>
                    </div>
                    <br />

                    <h1>3. Price Adjustment or Refund:</h1>
                    <div className='px-3'>
                        <p><b>a.</b><span> If the User decides to proceed with the services after the confirmation discussion, the token payment will be adjusted against the total cost of the services.</span></p>
                        <p><b>b.</b><span> If the User chooses not to proceed with the services after the confirmation discussion, ITW will refund the token payment received, in full.</span></p>
                    </div>
                    <br />

                    <h1>4. Final Payment: </h1>
                    <div className='px-3'>
                        <p>The final payment for the services, after adjusting the token amount, must be made as per the agreed terms communicated by the ITW representative. Failure to make the payment within the stipulated time may result in the cancellation of the services.</p>
                    </div>
                    <br />

                    <h1>5. No Guarantee of Service Availability:</h1>
                    <div className='px-3'>
                        <p>Please note that the payment of the token amount does not guarantee the availability or confirmation of the services. The services will be confirmed only after discussion with the User and based on availability.</p>
                    </div>
                    <br />

                    <h1>6. Modification of Terms:</h1>
                    <div className='px-3'>
                        <p>ITW reserves the right to modify these payment terms and conditions at any time without prior notice. The updated terms will be available on our website, and continued use of our services will be considered as acceptance of the modified terms.</p>
                    </div>
                    <br />
                    </Col>
                </Row>
            </Container>
    )
}

export default PaymentTermsAndCondition
