import React from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  Container,
  FormHelperText,
  Grid,
} from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import { countrylist } from "../../../services/countryList";
import "../../flights/flight-booking/flight-summary/flightsummary.css";
import { emailValidator, fnameVali, lnameVali } from "../../user/validation";
import { authPost } from "../../../utility/apiConfig";
import { API_BASE_URL } from "../../../utility/apiConstants";

const ContactForm = () => {
  const [contactDetails, setContactDetails] = React.useState({
    title: "Mr",
    firstName: "",
    lastName: "",
    countryCode: "",
    phone: "",
    email: "",
    packageType: "",
    remarks: "",
  });
  const [error, setError] = React.useState({
    title: null,
    firstName: null,
    lastName: null,
    countryCode: null,
    phone: null,
    email: null,
    packageType: null,
    remarks: null,
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let scripts =
      "https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js";
    var script = document.createElement("script");
    script.src = scripts;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleContactDetails = (event) => {
    error[event.target.name] = null;
    setContactDetails({
      ...contactDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneChange = (e) => {
    setError({ ...error, phone: null });
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 10) {
      setContactDetails({ ...contactDetails, phone: onlyNums });
    }
  };

  function isValidNumber(number) {
    return new window.libphonenumber.parsePhoneNumber(number).isValid();
  }

  const checkValidation = () => {
    var number = contactDetails.phone;
    var country = contactDetails.countryCode;
    var phoneNumber = country + " " + number;

    let titleErr = contactDetails.title === "" ? "Please choose title" : null;
    let firstNameErr = fnameVali(contactDetails.firstName);
    let lastNameErr = lnameVali(contactDetails.lastName);
    let countryCodeErr =
      contactDetails.countryCode === "" ? "Please enter country code" : null;
    let phoneErr =
      contactDetails.phone === ""
        ? "Please enter phone number"
        : phoneNumber.length <= 6
        ? "Too short"
        : isValidNumber(phoneNumber)
        ? null
        : "Please enter a valid phone number.";
    let emailErr = emailValidator(contactDetails.email);
    let packageTypeErr =
      contactDetails.packageType === "" ? "Please choose package type" : null;
    let remarksErr =
      contactDetails.remarks === "" ? "Please enter remarks" : null;
    if (
      titleErr ||
      firstNameErr ||
      lastNameErr ||
      countryCodeErr ||
      phoneErr ||
      emailErr ||
      packageTypeErr ||
      remarksErr
    ) {
      setError({
        ...error,
        title: titleErr,
        firstName: firstNameErr,
        lastName: lastNameErr,
        countryCode: countryCodeErr,
        phone: phoneErr,
        email: emailErr,
        packageType: packageTypeErr,
        remarks: remarksErr,
      });
      return;
    } else {
      handleContactSubmit();
    }
  };

  const handleContactSubmit = () => {
    setLoading(true);
    let dataToSend = {
      title: contactDetails.title,
      first_name: contactDetails.firstName,
      last_name: contactDetails.lastName,
      country_code: contactDetails.countryCode,
      phone: contactDetails.phone,
      email: contactDetails.email,
      package_type: contactDetails.packageType,
      remarks: contactDetails.remarks,
    };
    try {
        authPost(API_BASE_URL + 'tours/contact_us', dataToSend)
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    alert(response.data?.message ?? 'Form submitted successfully')
                } else {
                    alert("Something went wrong!\n please try again...")
                }
            }).catch((error) => {
                console.log("Error", error)
                setLoading(false);
            })
    } catch (error) {
        console.log("Error", error)
        setLoading(false);
    }
  };

  return (
    <>
      {/* <Banner /> */}
      <Grid container spacing={1} className="contact-form">
        <Grid item xs={12} sm={12} md={8} className="m-auto">
          <Container className="bg-white tabsrow">
            <Box className="card-body contact-card-body">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <h1 className="font-large-bold text-center">Contact Us</h1>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Box className="font-12">Title</Box>
                  <Select
                    id="standard-select-title"
                    variant="outlined"
                    name="title"
                    value={contactDetails.title}
                    onChange={handleContactDetails}
                  >
                    <MenuItem value={""} selected>
                      Select Title
                    </MenuItem>
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                    <MenuItem value={"Mrs"}>Ms</MenuItem>
                  </Select>
                  {error.title !== null && (
                    <FormHelperText style={{ color: "red" }}>
                      {error.title}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box className="font-12">First Name</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    className="inputboxdetails"
                    placeholder="First Name"
                    name="firstName"
                    value={contactDetails.firstName}
                    onChange={handleContactDetails}
                    error={error.firstName}
                    helperText={error.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box className="font-12">Last Name</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="text"
                    className="inputboxdetails"
                    placeholder="Last Name"
                    name="lastName"
                    value={contactDetails.lastName}
                    onChange={handleContactDetails}
                    error={error.lastName}
                    helperText={error.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className="setinputBox">
                    <Box className="font-12">Mobile Number</Box>
                    <Box className="flexBoxdiv">
                      <Select
                        className="countrycode"
                        id="standard-select-title"
                        variant="outlined"
                        size="small"
                        name="countryCode"
                        value={contactDetails.countryCode}
                        onChange={handleContactDetails}
                        displayEmpty
                      >
                        <MenuItem value={"India (+91)"}>India (+91)</MenuItem>
                        {countrylist.map((option) => (
                          <MenuItem
                            key={option.origin}
                            value={option.country_code}
                          >
                            {option.name + " (" + option.country_code + ")"}
                          </MenuItem>
                        ))}
                      </Select>
                      {error.countryCode !== null && (
                        <FormHelperText style={{ color: "red" }}>
                          {error.countryCode}
                        </FormHelperText>
                      )}
                      <TextField
                        variant="outlined"
                        size="small"
                        className="inputboxdetails"
                        placeholder="Mobile Number"
                        name="phone"
                        value={contactDetails.phone}
                        onChange={handlePhoneChange}
                        error={error.phone}
                        helperText={error.phone}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="setinputBox ">
                    <Box className="font-12">Email Address</Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      className="inputboxdetails"
                      placeholder="Email Address"
                      name="email"
                      value={contactDetails.email}
                      onChange={handleContactDetails}
                      error={error.email}
                      helperText={error.email}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="font-12">Package Type</Box>
                  <Select
                    id="standard-select-title"
                    variant="outlined"
                    name="packageType"
                    value={contactDetails.packageType}
                    onChange={handleContactDetails}
                    error={error.packageType}
                    helperText={error.packageType}
                  >
                    <MenuItem value={""} selected>
                      Package type
                    </MenuItem>
                    <MenuItem value={"Cricket"}>Cricket</MenuItem>
                    <MenuItem value={"Footbal"}>Footbal</MenuItem>
                    <MenuItem value={"Golf"}>Golf</MenuItem>
                    <MenuItem value={"Tennis"}>Tennis</MenuItem>
                    <MenuItem value={"Motor Sports"}>Motor Sports</MenuItem>
                    <MenuItem value={"Badminton"}>Badminton</MenuItem>
                    <MenuItem value={"Marathon"}>Marathon</MenuItem>
                  </Select>
                  {error.packageType !== null && (
                    <FormHelperText style={{ color: "red" }}>
                      {error.packageType}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="font-12">Remarks</Box>
                  <TextField
                    variant="outlined"
                    size="small"
                    className="inputboxdetails"
                    placeholder="Enter your remarks here"
                    name="remarks"
                    value={contactDetails.remarks}
                    onChange={handleContactDetails}
                    error={error.remarks}
                    helperText={error.remarks}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="container"
                    className="btn btn-primary-full my-3"
                    onClick={checkValidation}
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactForm;
